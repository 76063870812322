








































import useSelectItems from "@/use/selectItems";
import { defineComponent } from "@vue/composition-api";

export default defineComponent({
  props: {
    value: {
      type: Object,
      required: false,
      default: () => ({
        name: "",
        language: "",
        category: "",
        photo: "",
        description: "",
      }),
    },
  },

  setup(_, { root }) {
    const { languageItems } = useSelectItems({ root });

    const categoryItems = ["brak", "*", "**", "***", "****", "*****"];

    return { languageItems, categoryItems };
  },
});
